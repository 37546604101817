import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useDocumentList(isTemplate) {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'number', label: i18nT(`Number`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'invoice_date', label: i18nT(`Invoice date`), sortable: true, thClass: "sk-header-content" },
    { key: 'due_date', label: i18nT(`Due date`), sortable: true },
    { key: 'total_amount', label: i18nT(`Total Amount`), sortable: true, thClass: "sk-header-content"  },
    { key: 'vendor', label: i18nT(`Vendor`), sortable: true },

    { key: 'PaidStatus', label: i18nT(`Status`), sortable: true },
    { key: 'DueAmount', label: i18nT(`Open balance`), sortable: true, thClass: "sk-header-content"  },
    { key: 'payment_type', label: i18nT(`Payment type`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ];
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const invoiceType = ref({ value: -1, text: i18nT('All types') });
  const recordType = ref({ value: -1, text: i18nT('All types') });
  const vendor = ref({ value: 0, text: i18nT('All') });
  const paymentType = ref({ value: -1, text: i18nT('All') });
  const paymentCategory = ref({ value: -1, text: i18nT('All') });
  const startDate = ref(moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'));
  const endDate = ref('');
  const lastInvoiceDate = ref('')
  const documents = ref([]);
  const documentFilter = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    if(refDocumentListTable && refDocumentListTable.value){
      refDocumentListTable.value.refresh();
    }

  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      invoiceType,
      recordType,
      documentFilter,
      vendor,
      paymentType,
      startDate,
      endDate,
      paymentCategory,
      isSortDirDesc,
      sortBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value,
      start_date: startDate.value,
      end_date: endDate.value,
      invoicing: 1,
    };

    if (isTemplate) {
      params.templates = 1;
    }

    // if (invoiceType.value!= -1) {
    //   params['filters[type]'] = invoiceType.value.value;
    // }
    if (recordType.value.value != -1) {
      params['filters[record_type]'] = recordType.value.value;
    }
    if (vendor.value.value != -1) {
      params['filters[vendor]'] = vendor.value.value;
    }
    if (paymentType.value.value != -1) {
      params['filters[payment_type]'] = paymentType.value.value;
    }
    if (paymentCategory.value.value != -1) {
      params['filters[category]'] = paymentCategory.value.value;
    }
    if(startDate.value != ''){
        params['filters[start_date]'] = startDate.value;
    }
    if(endDate.value != ''){
        params['filters[end_date]'] = endDate.value;
    }

    axios
      .get(`invoices`, {
        params,
      })
      .then(({ data }) => {
        documents.value = data.data.invoices;
        lastInvoiceDate.value = data.data.last_invoice_date
        callback(data.data.invoices);

        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    invoiceType,
    recordType,
    vendor,
    paymentType,
    paymentCategory,
    startDate,
    endDate,
    documents,
    documentFilter,
    lastInvoiceDate,
    refetchData,
  };
}
